<template>
  <div class="layout_box">
    <el-container v-if="this.$route.meta.keepAlive">
      <!-- <div v-if="this.$route.meta.keepAlive"> -->
      <div :class="[sliderFlag ? 'aside_box_stow' : 'aside_box']">
        <!-- 左侧区域 -->
        <keep-alive>
          <SlideBar @change="costFlag"></SlideBar>
        </keep-alive>

      </div>
      <!-- <div class="contentBox"> -->
      <div :class="[sliderFlag ? 'contentBox_stow' : 'contentBox']">
        <div class="header_box">
          <keep-alive>
            <HeadBar></HeadBar>
          </keep-alive>
          <!-- 头部 -->
        </div>

        <div class="main_box_shadow">
          <div :class="[flag == true ? 'main_box_workbench' : 'main_box']">
            <AppMain></AppMain>
            <!-- 内容区域 -->
          </div>
        </div>
      </div>
      <!-- </div> -->
    </el-container>
    <router-view :key='$route.fullPath' v-if="!this.$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { HeadBar, SlideBar, AppMain } from './components'
import { haveNews, editBright } from '@/api/api'
export default {
  data () {
    return {
      sliderFlag: '',
      flag: true,
      isRedPoint: 0
      //  opened: false
    }
  },
  mounted () {
    // if (this.$route.fullPath == '/workbench') {
    //   this.$store.isFlag = true
    // } else {
    //   this.$store.isFlag = false
    // }
  },
  created () {
    if (this.$route.fullPath == '/workbench') {
      this.flag = true
      // this.$store.isFlag = true
    } else {
      this.flag = false
    }
  },
  updated () {
    if (this.$route.fullPath == '/workbench') {
      this.flag = true
      // this.$store.isFlag = true
    } else {
      this.flag = false
    }
  },
  methods: {
    costFlag (val1, val2) {
      if (val2 == undefined) { // 点击的是收缩按钮
        const type = typeof val1
        if (type === 'boolean') {
          this.sliderFlag = val1// 控制侧边栏收缩
        } else if (type === 'string') {
          if (val1 == './workbench') {
            this.flag = true
            localStorage.setItem('isFlag', this.flag)
          } else {
            this.flag = false
            localStorage.setItem('isFlag', this.flag)
          }
        }
      } else { // 点击的是导航栏
        if (val1 == './workbench') {
          this.flag = true
          localStorage.setItem('isFlag', this.flag)
        } else {
          this.flag = false
          localStorage.setItem('isFlag', this.flag)
        }
        this.sliderFlag = val2
      }
    }
  },
  components: {
    HeadBar,
    SlideBar,
    AppMain
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    // 是否关闭侧边栏
    isCollapse () {
      // return !this.sidebar.opened
    }
  }
}
</script>

<style>
.main_box_shadow .el-card__body,
.el-main {
  padding: 0 !important;
}

.layout_box {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.el-aside {
  box-sizing: border-box;
  flex-shrink: 0;
  height: 100vh;
  overflow: hidden !important;
}

/* 加过渡给侧边导航*/

.aside_box {
  width: 270px !important;
}

.aside_box_stow {
  width: 100px !important;
}

.contentBox {
  display: flex;
  flex-direction: column;
  width: calc(100% - 270px);
  height: 100vh;
}

.contentBox_stow {
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
  height: 100vh;
}

.header_box {
  height: 100px !important;
  padding: 0 !important;
}

.main_box {
  width: 100%;
  background: #ffffff;
  border-radius: 40px 0px 0px 0px;
  margin-left: 10px;
  margin-top: -10px;
  padding: 20px 0 80px 30px !important;
}

.main_box_workbench {
  width: 100%;
  background: #ffffff;
  border-radius: 40px 0px 0px 0px;
  overflow-y: auto;
  margin-left: 10px;
  margin-top: -10px;
}

.main_box:before {
  position: absolute;
  z-index: 9;
}

.main_box_shadow {
  display: flex;
  height: 100vh;
  background: #ffffff;
  border-radius: 40px 0px 0px 0px;
  background-color: rgb(255 255 255 / 19%);
}

/* 媒体查询 自适应 */
@media screen and (max-width: 1680px) {
  .aside_box {
    width: 180px !important;
  }

  .aside_box_stow {
    width: 66px !important;
  }

  .contentBox {
    width: calc(100% - 180px);
  }

  .contentBox_stow {
    width: calc(100% - 60px);
  }

  .header_box {
    height: 60px !important;
    padding: 0 !important;
  }

  .main_box {
    border-radius: 27px 0px 0px 0px;
    margin-left: 7px;
    margin-top: -7px;
    padding: 20px 0 50px 15px !important;
  }

  .main_box_workbench {
    border-radius: 27px 0px 0px 0px;
    margin-left: 7px;
    margin-top: -7px;
  }

  .main_box_shadow {
    height: calc(100vh - 60px);
    border-radius: 27px 0px 0px 0px;
  }
}
</style>

import axios from 'axios'
import router from '@/router/index'
// 这里引用了element的loading全屏加载
import { Loading, Message } from 'element-ui'
axios.defaults.withCredentials = true
const service = axios.create({
  // baseURL: '/devapi',
  // timeout: 300000 // 设置请求超时时间
})
let loading = ''
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理
    if (!(config.headers['Content-Type'])) {
      loading = Loading.service({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255,255,255,0.7)',
        customClass: 'request-loading'
      })
      if (config.method == 'post' && config.type == 'upload') {
        config.headers['Content-Type'] = 'multipart/form-data'
        config.headers.Authorization = localStorage.getItem('token')
      } else if (config.method == 'post' && config.type == 'download') {
        // 导出csv文件
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        config.responseType = 'blob'
        config.headers.Authorization = localStorage.getItem('token')
      } else if (config.method == 'get' && config.type == 'export') {
        // 导出csv文件
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        config.responseType = 'blob'
        config.headers.Authorization = localStorage.getItem('token')
      } else if (config.method == 'post' && config.type == 'import') {
        // 导出csv文件
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        config.responseType = 'blob'
        config.headers.Authorization = localStorage.getItem('token')
      } else if (config.method == 'post' && config.rzheader) {
        // 导出csv文件
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        config.headers.Authorization = localStorage.getItem('token')
        for (const key in config.data) {
          if (config.data[key] === '') {
            delete config.data[key]
          }
        }
        config.data = JSON.stringify(config.data)
        config.headers.appId = config.rzheader.applId
        config.headers.signature = config.rzheader.signature
        config.headers.nonce = config.rzheader.nonce
        config.headers.timestamp = config.rzheader.timestamp
      } else if (config.method == 'post') {
        // config.headers['Content-Type'] = 'multipart/form-data'
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        config.headers.Authorization = localStorage.getItem('token')
        let url = []
        if (process.env.NODE_ENV == 'development') {
          url = [
            '/devapi/apiWeb/patient/editPatient'
          ]
        } else {
          url = [
            '/apiWeb/patient/editPatient'
          ]
        }
        if (config.url.includes(url)) {
          console.log(config.url)
        } else {
          for (const key in config.data) {
            if (config.data[key] === '') {
              delete config.data[key]
            }
          }
        }
        config.data = JSON.stringify(config.data)
      } else {
        config.headers['Content-Type'] =
          'application/x-www-form-urlencoded;charset=UTF-8'
        config.data = JSON.stringify(config.data)
        config.headers.Authorization = localStorage.getItem('token')
      }
    }

    return config
  },
  (error) => {
    loading.close()
    // 发送失败
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    loading.close()
    // dataAxios 是 axios 返回数据中的 data
    // loadingInstance.close();
    if (response.config.type == 'download') {
      return response
    } else if (response.headers['content-disposition']) {
      return response
    } else {
      const dataAxios = response.data
      // console.log("dataAxios", response)
      // 这个状态码是和后端约定的
      if (dataAxios.code == 500) {
        Message.closeAll()
        Message({
          message: dataAxios.msg,
          type: 'error'
        })
      } else if (dataAxios.code == 900) {
        Message.closeAll()
        Message({
          message: dataAxios.msg,
          type: 'error'
        })
        setTimeout(() => {
          window.location.href = window.location.origin
        }, 3000)
        localStorage.clear()
      } else if (dataAxios.code == 302 || dataAxios.code == 403) {
        router.push({ path: '/login' })
      }
      return dataAxios
    }
  },
  (error) => {
    if (error.request.status == 0) {
      router.push({ path: '/login' })
      // return error.response.data
    } else if (error.request.status == 403) {
      router.replace({ path: '/login' })
    }
    Promise.reject(error)
    loading.close()
    // if (error.response.status == 401) {
    //   process.env.NODE_ENV == "development" ?
    //     window.location.href = 'http://192.168.100.14:888/' :
    //     window.location.href = window.location.origin + '/'

    //   localStorage.clear()
    //   // 过期清除cookie
    //   let keys = document.cookie.match(/[^ =;]+(?==)/g)
    //   if (keys) {
    //     for (let i = keys.length; i--;) {
    //       document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() // 清除当前域名下的,例如：m.ratingdog.cn
    //       document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() // 清除当前域名下的，例如 .m.ratingdog.cn
    //       document.cookie = keys[i] + '=0;path=/;domain=ratingdog.cn;expires=' + new Date(0).toUTCString() // 清除一级域名下的或指定的，例如 .ratingdog.cn
    //     }
    //   }
    // }
    return Promise.reject(error)
  }
)
// document.cookie = "EMS_TOKEN=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjQ3NTI5ODc4NDIsImlkIjoxMTExMTExMTExfQ.T5kOkVmHrnCxM5V16e9V6es9egMBOjbHKIZbd1i7pWY"

export function apiUrl () {
  // return 'http://192.168.100.102:9010'
  // return 'http://180.169.235.162:8085/yhwy_test_api' // 测试环境
  return 'https://app.shkyin.com/api' // 线上康睿
}

export function socketApiUrl () {
  // return 'ws://180.169.235.162:8085/yhwy_test_api/websocket' // 测试
  return 'wss://app.shkyin.com/api/websocket/' // 线上康睿
}
export default service
